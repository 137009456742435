.mainContainer {
    max-width: 400px;
    margin: 0 auto;
    padding: 0px 10px 0px 10px;
}

input[type="text"] {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    height: 25px;
}

button[type="submit"] {
    width: 100%;
    display: block;
    font-size: 32px;
}

.radioOption {
    display: flex;
    height: 30px;
    align-content: center;
}

.radioOption > label {
    display: block;
}