.question {
    text-align: center;
}

input[type="number"] {
    width: 100%;
    box-sizing: border-box;
    font-size: 32px;
}

button[type="submit"] {
    margin-top: 20px;
}