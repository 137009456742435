.multipleChoice {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.option {
    border: 1px solid black;
    padding: 20px 0px 20px 0px;
    margin-top: 20px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    min-width: 85px;
}

.selected {
    background-color: #c9f8c9;
}